export const ListaSinonimos = [
    {
        descProducto:"CUARTIROLO",
        cod:"001"
    },
    {   
        descProducto:"cremon",
        cod:"001"
    },
    {   
        descProducto:"mantecoso",
        cod:"001"
    },
    {   
        descProducto:"fresco",
        cod:"001"
    },
    {
        descProducto:"port salud",
        cod: "002"
    },
    {   
        descProducto:"port salut",
        cod:"002"
    },
    {   
        descProducto:"port salud sin sal",
        cod:"003"
    },
    {   
        descProducto:"port salut sin sal",
        cod:"003"
    },
    {   
        descProducto:"queso de rallar",
        cod:"004"
    },
    {   
        descProducto:"rayar",
        cod:"004"
    },

    {   
        descProducto:"rallar",
        cod:"004"
    },
    {
        descProducto:"cascara colorada",
        cod:"006"
    },
    {
        descProducto:"mar del plata",
        cod:"006"
    },
    {
        descProducto:"cascara roja",
        cod:"006"
    },
    {
        descProducto:"feteable",
        cod:"009"
    },
    {
        descProducto:"queso fetas",
        cod:"009"
    },
    {
        descProducto:"queso barra",
        cod:"009"
    },
    {
        descProducto:"queso maquina",
        cod:"009"
    },
    {
        descProducto:"queso máquina",
        cod:"009"
    },
    {
        descProducto:"pategras",
        cod:"011"
    },
    {
        descProducto:"sinsal",
        cod:"011"
    },
    {
        descProducto:"verde",
        cod:"011"
    },
    {
        descProducto:"ricotta",
        cod:"012"
    },
    {
        descProducto:"proboleta",
        cod:"014"
    },
    {
        descProducto:"provoleta",
        cod:"014"
    },
    {
        descProducto:"chedar",
        cod:"016"
    },
    {   
        descProducto:"queso de rallar",
        cod:"018"
    },
    {   
        descProducto:"rayar",
        cod:"018"
    },

    {   
        descProducto:"rallar",
        cod:"018"
    },
    {   
        descProducto:"queso de rallar",
        cod:"019"
    },
    {   
        descProducto:"rayar",
        cod:"019"
    },

    {   
        descProducto:"rallar",
        cod:"019"
    },
    {   
        descProducto:"queso de rallar",
        cod:"020"
    },
    {   
        descProducto:"rayar",
        cod:"020"
    },

    {   
        descProducto:"rallar",
        cod:"020"
    },
    {
        descProducto:"regianito",
        cod:"021"
    },
    {
        descProducto:"sbrinz",
        cod:"021"
    },
    {
        descProducto:"regianito",
        cod:"023"
    },
    {
        descProducto:"sbrinz",
        cod:"023"
    },
    {
        descProducto:"musarela",
        cod:"029"
    },
    {
        descProducto:"muzzarela",
        cod:"029"
    },
    {
        descProducto:"mutsarela",
        cod:"029"
    },
    {
        descProducto:"moozarella",
        cod:"029"
    },
    {
        descProducto:"mozarela",
        cod:"029"
    },
    {
        descProducto:"mussarela",
        cod:"029"
    },
    {
        descProducto:"musarela",
        cod:"030"
    },
    {
        descProducto:"muzzarela",
        cod:"030"
    },
    {
        descProducto:"mutsarela",
        cod:"030"
    },
    {
        descProducto:"moozarella",
        cod:"030"
    },
    {
        descProducto:"mozarela",
        cod:"030"
    },
    {
        descProducto:"mussarela",
        cod:"030"
    },
    {
        descProducto:"chedar",
        cod:"039"
    },
    {
        descProducto:"dulce de leche",
        cod:"040"
    },
    {
        descProducto:"dulce de leche",
        cod:"042"
    },
    {
        descProducto:"dulce de leche",
        cod:"043"
    },
    {
        descProducto:"dulce de leche",
        cod:"044"
    },
    {
        descProducto:"dulce de leche",
        cod:"047"
    },
    {
        descProducto:"dulce de leche",
        cod:"070"
    },
    {
        descProducto:"dulce de leche",
        cod:"071"
    },
    {
        descProducto:"regalo",
        cod:"010"
    },
    {
        descProducto:"presente",
        cod:"010"
    },
    {
        descProducto:"picado",
        cod:"100"
    },
    {
        descProducto:"picado",
        cod:"101"
    },
    {
        descProducto:"jamon crudo",
        cod:"111"
    },
    {
        descProducto:"jamon",
        cod:"111"
    },
    {
        descProducto:"crudo",
        cod:"111"
    },
    {
        descProducto:"jamones crudos",
        cod:"111"
    },
    {
        descProducto:"jamon",
        cod:"145"
    },
    {
        descProducto:"jamón",
        cod:"145"
    },
    {
        descProducto:"jamon",
        cod:"150"
    },
    {
        descProducto:"jamón",
        cod:"150"
    },
    {
        descProducto:"picado",
        cod:"180"
    },
    {
        descProducto:"picado",
        cod:"181"
    },
    {
        descProducto:"aceitunas",
        cod:"249"
    },
    {
        descProducto:"aceitunas",
        cod:"250"
    },
    {
        descProducto:"aceitunas",
        cod:"252"
    },
    {
        descProducto:"aceitunas",
        cod:"253"
    },
    {
        descProducto:"aceitunas",
        cod:"254"
    },
    {
        descProducto:"roquefort",
        cod:"338"
    },
    {
        descProducto:"regalo",
        cod:"007"
    },
    {
        descProducto:"presente",
        cod:"007"
    },
    {
        descProducto:"rapiditas",
        cod:"7791218124055"
    },
    {
        descProducto:"rapiditas",
        cod:"7791218124062"
    },
    {
        descProducto:"pitzza",
        cod:"7795786000063"
    },
    {
        descProducto:"piza",
        cod:"7795786000063"
    },
    {
        descProducto:"pitza",
        cod:"7795786000063"
    },
    {
        descProducto:"pitzza",
        cod:"7795786000070"
    },
    {
        descProducto:"piza",
        cod:"7795786000070"
    },
    {
        descProducto:"pitza",
        cod:"7795786000070"
    },
    {
        descProducto:"saborisado",
        cod:"7795786000117"
    },
    {
        descProducto:"saborisado",
        cod:"7795786000148"
    },
    {
        descProducto:"saborisado",
        cod:"7795786000155"
    },
    {
        descProducto:"saborisado",
        cod:"7795786000179"
    },
    {
        descProducto:"saborisado",
        cod:"7795786000193"
    },
    {
        descProducto:"saborisado",
        cod:"7795786000209"
    },
    {
        descProducto:"pitzza",
        cod:"7795786000216"
    },
    {
        descProducto:"piza",
        cod:"7795786000216"
    },
    {
        descProducto:"pitza",
        cod:"7795786000216"
    },
    {
        descProducto:"dulce de leche",
        cod:"7795786000414"
    },
    {
        descProducto:"untable",
        cod:"7795786000681"
    },
    {
        descProducto:"queso crema",
        cod:"7795786000681"
    },
    {
        descProducto:"untable",
        cod:"7795786000698"
    },
    {
        descProducto:"queso crema light",
        cod:"7795786000698"
    },
    {
        descProducto:"queso",
        cod:"7795786000742"
    },
    {
        descProducto:"rallado",
        cod:"7795786000742"
    },
    {
        descProducto:"queso rallado",
        cod:"azul"
    },
    {
        descProducto:"muzzarelita",
        cod:"7795786000759"
    },
    {
        descProducto:"muzza",
        cod:"7795786000759"
    },
    {
        descProducto:"queso",
        cod:"7795786000759"
    },
    {
        descProducto:"mozzarella",
        cod:"7795786000759"
    },
    {
        descProducto:"manteca",
        cod:"7795786000797"
    },
    {
        descProducto:"luzazul",
        cod:"7795786000797"
    },
    {
        descProducto:"aceitunas",
        cod:"7797283001116"
    },
    {
        descProducto:"aceitunas",
        cod:"77972830011030"
    },
    {
        descProducto:"aceitunas",
        cod:"7797283002458"
    },
    {
        descProducto:"pikles",
        cod:"7797283002618"
    },
    {
        descProducto:"pikles",
        cod:"7797283002649"
    },
    {
        descProducto:"aceitunas",
        cod:"7797283003462"
    },
    {
        descProducto:"aceitunas",
        cod:"7797283003479"
    },
    {
        descProducto:"aceitunas",
        cod:"7797283005473"
    },
    {
        descProducto:"aceitunas",
        cod:"7797283006012"
    },
    {
        descProducto:"aceitunas",
        cod:"7797283012341"
    },
    {
        descProducto:"aceitunas",
        cod:"7797283054426"
    },
    {
        descProducto:"aceitunas",
        cod:"7797283213410"
    },
    {
        descProducto:"vino",
        cod:"7798347330012"
    },
    {
        descProducto:"tinto",
        cod:"7798347330012"
    },
    {
        descProducto:"vino",
        cod:"7798347330029"
    },
    {
        descProducto:"tinto",
        cod:"7798347330029"
    },
    {
        descProducto:"vino",
        cod:"7798347330050"
    },
    {
        descProducto:"blanco",
        cod:"7798347330050"
    },
    {
        descProducto:"vino",
        cod:"7798347330067"
    },
    {
        descProducto:"rosado",
        cod:"7798347330067"
    },
    {
        descProducto:"rosee",
        cod:"7798347330067"
    },
    {
        descProducto:"vino",
        cod:"7798347330364"
    },
    {
        descProducto:"blanco",
        cod:"7798347330364"
    },
    {
        descProducto:"vino",
        cod:"7798347330395"
    },
    {
        descProducto:"rosado",
        cod:"7798347330395"
    },
    {
        descProducto:"rojo",
        cod:"7798347330395"
    },
    {
        descProducto:"rosee",
        cod:"7798347330395"
    },
    {
        descProducto:"tinto",
        cod:"7798347330401"
    },
    {
        descProducto:"vino",
        cod:"7798347330401"
    },
    {
        descProducto:"vino",
        cod:"7798347330418"
    },
    {
        descProducto:"tinto",
        cod:"7798347330418"
    },
    {
        descProducto:"vino",
        cod:"7798347330425"
    },
    {
        descProducto:"tinto",
        cod:"7798347330425"
    },
    {
        descProducto:"vino",
        cod:"7798347330555"
    },
    {
        descProducto:"champagne",
        cod:"7798347330555"
    },
    {
        descProducto:"champan",
        cod:"7798347330555"
    },
    {
        descProducto:"blanco",
        cod:"7798347330555"
    },
    {
        descProducto:"burbujas",
        cod:"7798347330555"
    },
    {
        descProducto:"vino",
        cod:"7798347330586"
    },
    {
        descProducto:"champagne",
        cod:"7798347330586"
    },
    {
        descProducto:"champan",
        cod:"7798347330586"
    },
    {
        descProducto:"blanco",
        cod:"7798347330586"
    },
    {
        descProducto:"burbujas",
        cod:"7798347330586"
    },
    {
        descProducto:"vino",
        cod:"7798347330678"
    },
    {
        descProducto:"tinto",
        cod:"7798347330678"
    },
    {
        descProducto:"vino",
        cod:"7798347330692"
    },
    {
        descProducto:"tinto",
        cod:"7798347330692"
    },
    {
        descProducto:"regalo",
        cod:"008"
    },
    {
        descProducto:"presente",
        cod:"008"
    },
    {
        descProducto:"combo",
        cod:"861"
    },
    {
        descProducto:"duo",
        cod:"861"
    },
    {
        descProducto:"manteca",
        cod:"861"
    },
    {
        descProducto:"dulce",
        cod:"861"
    },
    {
        descProducto:"combo",
        cod:"862"
    },
    {
        descProducto:"mundial",
        cod:"862"
    },
    {
        descProducto:"combo",
        cod:"868"
    },
    {
        descProducto:"mundial",
        cod:"868"
    },
    {
        descProducto:"combo",
        cod:"869"
    },
    {
        descProducto:"mundial",
        cod:"869"
    },
    {
        descProducto:"combo",
        cod:"873"
    },
    {
        descProducto:"mundial",
        cod:"873"
    },
    {
        descProducto:"regalo",
        cod:"009"
    },
    {
        descProducto:"presente",
        cod:"009"
    }
]