export const infoProductosNutricional = [
    {
        cod:1,
        porcion:30,
        CPP:{
            valorEnergetico:94,
            carbohidratos:.3,
            proteinas:6.3,
            grasasTotales:7.5,
            grasasSaturadas:4.5,
            grasasTrans:0,
            fibraAlimentaria:0,
            sodio:165
        },
        VD:{
            valorEnergetico:5,
            carbohidratos:0,
            proteinas:8,
            grasasTotales:14,
            grasasSaturadas:21,
            grasasTrans:"-",
            fibraAlimentaria:0,
            sodio:7
        }
    },
    {
        cod:2,
        porcion:30,
        CPP:{
            valorEnergetico:92,
            carbohidratos:.3,
            proteinas:6.6,
            grasasTotales:7.2,
            grasasSaturadas:3,
            grasasTrans:0,
            fibraAlimentaria:0,
            sodio:82
        },
        VD:{
            valorEnergetico:5,
            carbohidratos:0,
            proteinas:9,
            grasasTotales:13,
            grasasSaturadas:14,
            grasasTrans:"-",
            fibraAlimentaria:0,
            sodio:3
        }
    },
    {
        cod:3,
        porcion:30,
        CPP:{
            valorEnergetico:64,
            carbohidratos:.2,
            proteinas:7.8,
            grasasTotales:3.6,
            grasasSaturadas:2.2,
            grasasTrans:0,
            fibraAlimentaria:0,
            sodio:25
        },
        VD:{
            valorEnergetico:3,
            carbohidratos:0,
            proteinas:10,
            grasasTotales:7,
            grasasSaturadas:10,
            grasasTrans:"-",
            fibraAlimentaria:0,
            sodio:1
        }
    },
    {
        cod:6,
        porcion:30,
        CPP:{
            valorEnergetico:111,
            carbohidratos:.4,
            proteinas:7.2,
            grasasTotales:8.9,
            grasasSaturadas:4.9,
            grasasTrans:0,
            fibraAlimentaria:0,
            sodio:203
        },
        VD:{
            valorEnergetico:6,
            carbohidratos:0,
            proteinas:10,
            grasasTotales:16,
            grasasSaturadas:22,
            grasasTrans:"-",
            fibraAlimentaria:0,
            sodio:8
        }
    },
    {
        cod:7,
        porcion:40,
        CPP:{
            valorEnergetico:188,
            carbohidratos:.4,
            proteinas:12.6,
            grasasTotales:15.8,
            grasasSaturadas:9.1,
            grasasTrans:0,
            fibraAlimentaria:0,
            sodio:165
        },
        VD:{
            valorEnergetico:9.4,
            carbohidratos:0,
            proteinas:16.8,
            grasasTotales:27.1,
            grasasSaturadas:42.2,
            grasasTrans:"-",
            fibraAlimentaria:0,
            sodio:7
        }
    },
    {
        cod:8,
        porcion:40,
        CPP:{
            valorEnergetico:189,
            carbohidratos:.4,
            proteinas:12.1,
            grasasTotales:15.4,
            grasasSaturadas:9.2,
            grasasTrans:0,
            fibraAlimentaria:0,
            sodio:108
        },
        VD:{
            valorEnergetico:9,
            carbohidratos:0,
            proteinas:16,
            grasasTotales:28,
            grasasSaturadas:43,
            grasasTrans:"-",
            fibraAlimentaria:0,
            sodio:5
        }
    },
    {
        cod:15,
        porcion:40,
        CPP:{
            valorEnergetico:189,
            carbohidratos:.4,
            proteinas:12.1,
            grasasTotales:15.4,
            grasasSaturadas:9.2,
            grasasTrans:0,
            fibraAlimentaria:0,
            sodio:108
        },
        VD:{
            valorEnergetico:9.45,
            carbohidratos:0,
            proteinas:16.1,
            grasasTotales:27.6,
            grasasSaturadas:42.8,
            grasasTrans:"-",
            fibraAlimentaria:0,
            sodio:5
        }
    },
    {
        cod:14,
        porcion:40,
        CPP:{
            valorEnergetico:150,
            carbohidratos:.4,
            proteinas:10.7,
            grasasTotales:11.7,
            grasasSaturadas:7,
            grasasTrans:0,
            fibraAlimentaria:0,
            sodio:226
        },
        VD:{
            valorEnergetico:7.5,
            carbohidratos:0.1,
            proteinas:14.3,
            grasasTotales:21.1,
            grasasSaturadas:31.8,
            grasasTrans:"-",
            fibraAlimentaria:0,
            sodio:9.4
        }
    },
    {
        cod:22,
        porcion:30,
        CPP:{
            valorEnergetico:125,
            carbohidratos:.9,
            proteinas:10,
            grasasTotales:9,
            grasasSaturadas:5,
            grasasTrans:0,
            fibraAlimentaria:0,
            sodio:292
        },
        VD:{
            valorEnergetico:6,
            carbohidratos:0,
            proteinas:13,
            grasasTotales:16,
            grasasSaturadas:23,
            grasasTrans:"-",
            fibraAlimentaria:0,
            sodio:12
        }
    },
    {
        cod:18,
        porcion:30,
        CPP:{
            valorEnergetico:125,
            carbohidratos:.9,
            proteinas:10,
            grasasTotales:9,
            grasasSaturadas:5,
            grasasTrans:0,
            fibraAlimentaria:0,
            sodio:292
        },
        VD:{
            valorEnergetico:6,
            carbohidratos:0,
            proteinas:13,
            grasasTotales:16,
            grasasSaturadas:23,
            grasasTrans:"-",
            fibraAlimentaria:0,
            sodio:12
        }
    },
    {
        cod:25,
        porcion:40,
        CPP:{
            valorEnergetico:180,
            carbohidratos:.4,
            proteinas:11.6,
            grasasTotales:14.7,
            grasasSaturadas:8.8,
            grasasTrans:0,
            fibraAlimentaria:0,
            sodio:226
        },
        VD:{
            valorEnergetico:7.5,
            carbohidratos:0.1,
            proteinas:15.5,
            grasasTotales:26.4,
            grasasSaturadas:40.9,
            grasasTrans:"-",
            fibraAlimentaria:0,
            sodio:9.4
        }
    },
    {
        cod:10,
        porcion:30,
        CPP:{
            valorEnergetico:111,
            carbohidratos:.4,
            proteinas:7.2,
            grasasTotales:8.9,
            grasasSaturadas:4.9,
            grasasTrans:0,
            fibraAlimentaria:0,
            sodio:203
        },
        VD:{
            valorEnergetico:6,
            carbohidratos:0,
            proteinas:10,
            grasasTotales:16,
            grasasSaturadas:22,
            grasasTrans:"-",
            fibraAlimentaria:0,
            sodio:8
        }
    },
    {
        cod:9,
        porcion:30,
        CPP:{
            valorEnergetico:111,
            carbohidratos:.4,
            proteinas:7.2,
            grasasTotales:8.9,
            grasasSaturadas:4.9,
            grasasTrans:0,
            fibraAlimentaria:0,
            sodio:203
        },
        VD:{
            valorEnergetico:6,
            carbohidratos:0,
            proteinas:10,
            grasasTotales:16,
            grasasSaturadas:22,
            grasasTrans:"-",
            fibraAlimentaria:0,
            sodio:8
        }
    }
]